// http://44.195.135.3:4000/
// //local

// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://192.168.7.37:5001/node_api/web/";
// const IMG_URL = "http://192.168.7.37:5001/api/uploads/images/";
// const LEAD_URL = "http://192.168.6.11:5001/api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://192.168.6.74:5001/";
//const REG_URL = "http://192.168.7.49:8111/enroll/";
// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://192.168.7.37:5001/node_api/web/";
// const IMG_URL = "http://192.168.7.37:5001/api/uploads/images/";
// const LEAD_URL = "http://192.168.6.11:5001/api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // // const DEFAULT_KEY = "903";
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://192.168.6.74:5001/";
// const REG_URL = "http://192.168.7.49:8111/enroll/";
// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://192.168.21.19:5000/node_api/web/";
// const IMG_URL = "http://192.168.7.37:5001/api/uploads/images/";
// const LEAD_URL = "http://192.168.6.11:5001/api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://192.168.6.74:5001/";
// const REG_URL = "http://192.168.7.49:8111/enroll/";

// 2. LIVE

import companyName from "../store/mobxStore/companyName";
const COMPANY_NAME = companyName.companyName ?? "Company Name";
const BASE_URL = "https://nexadmin.nexarise.com/node_api/web/";
const IMG_URL = "https://nexadmin.nexarise.com/node_api/uploads/images/";
const LEAD_URL = "https://nexadmin.nexarise.com/node_api/node_api/web/";
const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
const BASE = "https://nexadmin.nexarise.com/";

// 3.DEMO

// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://162.19.146.132:1268/node_api/web/";
// const IMG_URL = "http://162.19.146.132:1268/node_api/uploads/images/";
// const LEAD_URL = "http://162.19.146.132:2168/node_api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://162.19.146.132:1268/";

//4 .STAGING

// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "https://admin.nexbroadband.co.uk/node_api/web/";
// const IMG_URL = "https://admin.nexbroadband.co.uk/node_api/uploads/images/";
// const LEAD_URL = "https://admin.nexbroadband.co.uk/node_api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://162.19.146.132:1268/";

export {
  COMPANY_NAME,
  BASE_URL,
  IMG_URL,
  API_KEY,
  BASE,
  DEFAULT_KEY,
  LEAD_URL,
};
