import React, { memo, useEffect, useState } from "react";
import ProfileService from "../../../service/profile";
import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { debounce } from 'lodash';

export const SafeChargeCC = memo(
  ({ safeCharge, paymentData, product, cardDetails, userDetails }) => {
    console.log(paymentData);
    const [cardNumber, setCardNumber] = useState(null);
    const [sessionToken, setSessionToken] = useState(paymentData.sessionToken);
      const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [userPaymentId, setUserPyamentId] = useState();
    const [loader, setLoader] = useState(false);
    const [alert, SetAlert] = useState({
      show: false,
      type: "",
      message: "",
    });

    const service = new ProfileService();
    const history = useHistory();
    const createPayment = (e) => {

      setLoader(true);
      let data = {
        product_deatils: product,
        payement_method: "nuvei",
        user_payment_id: userPaymentId,
      };
      service.subscriptionUpdate(data).then((res) => {
        if (res.status) {
          SetAlert({
            ...alert,
            show: true,
            type: "success",
            message: "Payment completed successfully ",
            retry: false,
          });
          setLoader(false);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          history.push("/dashboard");
        } else {
          console.log(res)
          // SetAlert({
          //   ...alert,
          //   show: true,
          //   type: "danger",
          //   message: res.error,
          //   retry: true,
          // });
          setLoader(false);
        }
      });
      // if (userPaymentId) {
      //   setLoader(true);
      //   const paymentData = {
      //     sessionToken: sessionToken,
      //     userTokenId: userDetails?.user_token_id,
      //     paymentOption: {
      //       userPaymentOptionId: userPaymentId,
      //       card: {
      //         CVV: "123",
      //       },
      //     },
      //     billingAddress: {
      //       firstName: userDetails?.first_name,
      //       lastName: userDetails?.second_name,
      //       country: userDetails?.country,
      //       email: userDetails?.email,
      //     },
      //   };
      //   sendPaymentRequest(paymentData);
      // }
    };
    // Function to send payment request
    const sendPaymentRequest = (paymentOptions) => {
      try {
        safeCharge.createPayment(paymentOptions, (resp) => {
          if (
            resp.status == "ERROR" ||
            resp.result == "DECLINED" ||
            resp.result == "ERROR"
          ) {
            SetAlert({
              ...alert,
              show: true,
              type: "danger",
              message: resp?.error?.message
                ? resp?.error?.message
                : resp?.errorDescription,
            });
            setLoader(false);
          }
          if (resp.result == "APPROVED") {
            setLoader(false);
            handleSuccessfulPayment(resp);
          }
        });
      } catch (e) {
        console.error(e);
      }
    };

    const handleSuccessfulPayment = (resp) => {
      let data = {
        product_deatils: product,
        payment_response: resp,
        session_deatils: sessionToken,
        payement_method: "nuvei",
        user_payment_id: userPaymentId,
      };
      service.subscriptionUpdate(data).then((res) => {
        if (res.status) {
          SetAlert({
            ...alert,
            show: true,
            type: "success",
            message: "Payment completed successfully ",
            retry: false,
          });
          setLoader(false);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          history.push("/dashboard");
        } else {
          SetAlert({
            ...alert,
            show: true,
            type: "danger",
            message: res.error,
            retry: true,
          });
          setLoader(false);
        }
      });
    };

    const handleSavedCardSelect = (e) => {
      setUserPyamentId(e.target.value);
    };

     const handleClick = debounce(async () => {
    if(isButtonDisabled) return;
    setIsButtonDisabled(true);

    try {
       
      await performAsyncOperation();

    } catch (error) {
      console.error('Operation failed', error);

    }
    finally {
      setIsButtonDisabled(false);
    }
  }, 3000, { leading: true, trailing: false });

  const performAsyncOperation = () => {
  return new Promise((resolve, reject) => {
    setLoader(true);

    let data = {
      product_deatils: product, // Corrected typo from 'product_deatils' to 'product_details'
      payement_method: "nuvei", // Corrected typo from 'payement_method' to 'payment_method'
      user_payment_id: userPaymentId,
    };

    service.subscriptionUpdate(data).then((res) => {
      setLoader(false);
      
      if (res.status) {
        SetAlert({
          ...alert,
          show: true,
          type: "success",
          message: "Payment completed successfully ",
          retry: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        history.push("/dashboard");
        resolve('Operation completed');
      } else {
        console.log(res?.error?.error)
        SetAlert({
          ...alert,
          show: true,
          type: "danger",
          message: res?.error?.error?.gwErrorReason ? res?.error?.error?.gwErrorReason :"Something Went Wrong",
          retry: true,
        });
        reject(new Error('Operation failed'));
      }
    }).catch((error) => {
      setLoader(false);
      SetAlert({
        ...alert,
        show: true,
        type: "danger",
        message: error.message,
        retry: true,
      });
      reject(new Error('Operation failed'));
    });
  });
};

    return (
      <>
        {alert.show && (
          <Alert key={alert.type} variant={alert.type}>
            {alert.message}
            {alert?.retry && (
              <div>
                <a href="/pay-now">Retry Payment </a>
              </div>
            )}
          </Alert>
        )}
        <label htmlFor="car">Select Card </label>
        <div className="form-row" style={{ maxWidth: "500px" }}>
          <div className="input-group mb-6">
            <select
              className="custom-select"
              id="inputGroupSelect01"
              onChange={handleSavedCardSelect}
            >
              <option selected>Select Card</option>
              {cardDetails.map((card) => (
                <option key={card.id} value={card.user_payment_id}>
                  {"Card Number with " + card.card_number}
                </option>
              ))}
            </select>
          </div>
        </div>
  
        <div className="item buttons">
          <button
            className="pay-button"
            onClick={handleClick}
            disabled={loader || !userPaymentId}
          >

            {loader ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            ) : (
              ""
            )}
            Submit
          </button>
           {/* <button onClick={handleClick}>
        Click Me
      </button> */}
        </div>
      </>
    );
  }
);
